import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const overrideStrings = {
  allItemsAreSelected: "تم تحديد جميع العناصر.",
  clearSearch: "مسح البحث",
  clearSelected: "مسح المحدد",
  noOptions: "لا توجد خيارات",
  search: "بحث",
  selectAll: "اختر الكل",
  selectAllFiltered: "اختر الكل (المُفلتر)",
  selectSomeItems: "اختر...",
  create: "إنشاء",
};

export function transformChartData(
  chart: any,
  firstField: any,
  secoundField: any
): any {
  const transformedData = chart
    ? {
        id: "walletChart",
        data: chart[firstField]?.map((day: any, index: any) => ({
          x: day,
          y: chart[secoundField][index],
        })),
      }
    : [];

  return { id: "walletChart", data: [] };
}
// example usage:

// const data = transformChartData(chart);
//

export const handleKeyDownPositiveNumber = (
  event: React.KeyboardEvent<HTMLInputElement>
): void => {
  const { key, currentTarget } = event;
  const currentValue = currentTarget.value;

  // Allow backspace, delete, tab, escape, enter, period, and arrow keys
  if (
    key === "Backspace" ||
    key === "Delete" ||
    key === "Tab" ||
    key === "Escape" ||
    key === "Enter" ||
    key === "." ||
    (key >= "0" && key <= "9") ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    // Prevent entering negative sign or a zero as the first character
    if (key === "-") {
      event.preventDefault();
    }
    return;
  }

  // Prevent all other keys
  event.preventDefault();
};

// const handleKeyDownPositiveNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
//   // Allow only numeric keys and control keys (Backspace, Tab, Arrow keys, Delete)
//   if (!(e.key >= '0' && e.key <= '9' || e.key === 'Backspace' || e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete')) {
//     e.preventDefault();
//   }
// };
export const handleKeyDownNumber = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const { key } = event;
  if (
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight" ||
    key === "ArrowUp" ||
    key === "ArrowDown" ||
    key === "Tab" ||
    key === "Enter"
  ) {
    return; // Allow these keys without further checking
  }

  if (!/[0-9]/.test(key)) {
    event.preventDefault();
  }
};

export function toQueryString(params?: any): string {
  if (params)
    return Object.entries(params)
      .filter(([, value]) => value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      )
      .join("&");
  return "";
}

export function daysToMilliSecound(day: number) {
  return day * 24 * 60 * 60 * 100;
}
