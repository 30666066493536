import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SweetAlert = ({ title, text, iconName, buttonText }: any) => {
  return MySwal.fire({
    title,
    text,
    icon: iconName,
    confirmButtonText: buttonText,
    customClass: {
      popup: "custom-toast", // Add your custom styling here
    },
    position: "center",
    background: "#323538",
    color: "white",
  });
};

export default SweetAlert;
