import api from '../_axios';

const getCities = () => api.get('https://backend.eduvalu.com/api/v1/cities');
const getAreas = (cityid: number) =>
	api.get(`https://backend.eduvalu.com/api/v1/areas/${cityid}`);
const getLanguages = () =>
	api.get('https://backend.eduvalu.com/api/v1/languages');
const getMainEducationTypes = () =>
	api.get(`https://backend.eduvalu.com/api/v1/type-educations`);
const getEducationTypes = (school_year_id: any) =>
	api.get(
		`https://backend.eduvalu.com/api/v1/education-types?school_year_id=${school_year_id}`,
	);
const getSchoolYears = (mainEducationType?: number, teacherId?: number) =>
	api.get(
		`https://backend.eduvalu.com/api/v1/school-years?teacher_id=${teacherId ? teacherId : ''
		}&type_education_id=${mainEducationType ? mainEducationType : ''}`,
	);
const getSchoolTypes = () =>
	api.get('https://backend.eduvalu.com/api/v1/school-types');

const GENERALAPI = {
	getCities,
	getAreas,
	getLanguages,
	getMainEducationTypes,
	getEducationTypes,
	getSchoolYears,
	getSchoolTypes,
};
export default GENERALAPI;
