import { createSlice } from '@reduxjs/toolkit';
import { initialValues } from './types';
import thunks from './thunks';
import { RootState } from '../store';

export const slice = createSlice({
	name: 'general',
	initialState: initialValues,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(thunks.doGetALLCITIES.fulfilled, (state, action) => {
			//
			state.allCities = action.payload.data.data;
		});
		builder.addCase(thunks.doGetALLCITIES.rejected, (state, action: any) => {
			//
		});
		builder.addCase(thunks.doGetAllAreas.fulfilled, (state, action) => {
			//
			state.allAreas = action.payload.data.data;
		});
		builder.addCase(thunks.doGetAllAreas.rejected, (state, action: any) => {
			//
		});
		builder.addCase(thunks.doGetLanguages.fulfilled, (state, action) => {
			//
			state.languages = action.payload.data.data;
		});
		builder.addCase(thunks.doGetLanguages.rejected, (state, action: any) => {
			//
		});
		builder.addCase(
			thunks.doGetMainEducationTypes.fulfilled,
			(state, action) => {
				state.mainEducationTypes = action.payload.data.data;
			},
		);
		builder.addCase(
			thunks.doGetMainEducationTypes.rejected,
			(state, action: any) => {
				//
			},
		);
		builder.addCase(thunks.doGetEducationTypes.fulfilled, (state, action) => {
			state.educationTypes = action.payload.data.data;
		});
		builder.addCase(
			thunks.doGetEducationTypes.rejected,
			(state, action: any) => {
				//
			},
		);
		builder.addCase(thunks.doGetSchoolYears.fulfilled, (state, action) => {
			//
			state.schoolYears = action.payload.data.data;
		});
		builder.addCase(thunks.doGetSchoolYears.rejected, (state, action: any) => {
			//
		});
		builder.addCase(thunks.doGetSchoolTypes.fulfilled, (state, action) => {
			//
			state.schoolTypes = action.payload.data.data;
		});
		builder.addCase(thunks.doGetSchoolTypes.rejected, (state, action: any) => {
			//
		});
	},
});

export const SelectAllCities = (state: RootState) => state.general.allCities;
export const SelectAllAreas = (state: RootState) => state.general.allAreas;
export const SelectLang = (state: RootState) => state.general.languages;
export const SelectMainEducationTypes = (state: RootState) =>
	state.general.mainEducationTypes;
export const SelectEducationTypes = (state: RootState) =>
	state.general.educationTypes;
export const SelectSchoolYears = (state: RootState) =>
	state.general.schoolYears;
export const SelectSchoolTypes = (state: RootState) =>
	state.general.schoolTypes;

const GENERAL = {
	slice,
	thunks,
};

export default GENERAL;
