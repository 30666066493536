type TinitialValues = {
	allCities: [];
	allAreas: [];
	languages: [];
	mainEducationTypes: [];
	educationTypes: [];
	schoolYears: [];
	schoolTypes: [];
};

export const initialValues: TinitialValues = {
	allCities: [],
	allAreas: [],
	languages: [],
	mainEducationTypes: [],
	educationTypes: [],
	schoolYears: [],
	schoolTypes: [],
};
